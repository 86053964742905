<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getWhitelabels"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="getWhitelabels"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingWhitelables">
              <div class="table-responsive mb-0">
                <b-table
                  :items="whitelabels"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(is_vhost_exists)="data">
                    <feather
                      v-if="data.item.is_vhost_exists"
                      type="check"
                      class="icon-dual-success"
                    />
                  </template>
                  <template v-slot:cell(is_ssl_cert_exists)="data">
                    <feather
                      v-if="data.item.is_ssl_cert_exists"
                      type="check"
                      class="icon-dual-success"
                    />
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-button
                      v-if="!data.item.is_ssl_cert_exists"
                      variant="primary"
                      size="sm"
                      title="Request cert"
                      class="ml-1"
                      :disabled="loadingCert === data.item.id"
                      @click="requestCert(data.item)"
                    >
                      <b-spinner v-if="loadingCert === data.item.id" small />
                      <i v-else class="uil uil-globe"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalWhitelabels }} whitelables
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalWhitelabels"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'domain', sortable: true },
          { key: 'agency.name', label: 'Agency', sortable: true },
          { key: 'is_vhost_exists', label: 'Vhost exists', sortable: true },
          { key: 'is_ssl_cert_exists', label: 'Cert exists', sortable: true },
          { key: 'last_requested_cert_at', label: 'Last request cert' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          sort_by: 'domain',
          sort_desc: false,
        },
      },
      loadingWhitelables: false,
      loadingCert: false,
    }
  },

  computed: {
    whitelabels() {
      return this.$store.getters['whitelabel/all'] || []
    },

    totalWhitelabels() {
      return this.$store.getters['whitelabel/total']
    },
  },

  mounted() {
    if (this.$store.getters['whitelabel/all'] === null) {
      this.getWhitelabels()
    } else {
      this.datatable.queries = this.$store.getters['whitelabel/queries']
    }
  },

  methods: {
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getWhitelabels()
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getWhitelabels()
    },

    getWhitelabels() {
      this.loadingWhitelables = true

      this.$store
        .dispatch('whitelabel/getAll', this.datatable.queries)
        .then(() => {
          this.loadingWhitelables = false
        })
        .catch(() => {
          this.loadingWhitelables = false
        })
    },

    requestCert(whitelabel) {
      this.loadingCert = whitelabel.id

      this.$store
        .dispatch('whitelabel/requestCert', whitelabel.id)
        .then(() => {
          this.loadingCert = null
        })
        .catch(() => {
          this.loadingCert = null
        })
    },
  },
}
</script>
